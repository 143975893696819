import React from "react";
import { Link } from "gatsby";
import { IoIosArrowForward, IoIosCloudDone } from "react-icons/io";
import { IoCheckmarkCircle, IoPersonCircle } from "react-icons/io5";
import styled from "styled-components";

import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  ImageGraphic,
  Modal,
  CallToAction,
  Breadcrumb,
  CustomerQuoteLarge,
} from "../components/site/";

import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import MyHRLogo from "../images/logos/Other/MyHR_Logo.svg";
import PayHeroPlusMyHR from "../images/logos/PayHero/PayHeroPlusMyHR.svg";

const NumberBadge = styled.div`
  background-color: ${(props) => props.theme.colours.grey};
  color: ${(props) => props.theme.colours.dark};
  border-radius: 35px;
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  line-height: 35px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  text-align: center;
  user-select: none;
  margin-top: -7px;
`;

const MyHR = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout>
      <Seo
        title="MyHR + PayHero Integration | MR & Payroll"
        description="Simplify employee onboarding, leave and payroll with MyHR & PayHero. Get started for free."
        pathname={props.location.pathname}
      />
      {/* <Container className="myhr-primary -textCenter">
        <Wrapper stackGap={15}>
          <h4 className="-fontNormal">
            <b>Special offer for MyHR customers:</b> Get your first two months
            of PayHero FREE.{" "}
            <Link className="-fontBold" to="/offers/myhr">
              Learn More
            </Link>
            .
          </h4>
        </Wrapper>
      </Container> */}
      <Breadcrumb
        parentPage="Integrations"
        parentPageLink="/add-ons"
        currentPage="MyHR"
      />
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={45} stackGap={30} centerOnMobile>
              <img
                src={PayHeroPlusMyHR}
                alt="PayHero + MyHR Integration"
                height="40"
              />
              <Box stackGap={10}>
                <h1 className="-fontLarge">
                  Complete people and payroll management
                </h1>
                <h4>
                  Using MyHR and PayHero is a fast track to streamlining your
                  people and payroll processes. It’s the easy way to hire, pay
                  and manage employees and compliance.
                </h4>
              </Box>
              <FlexButtons>
                <Button to="/signup" className="primary -lg gtm-cta">
                  Get Started with PayHero
                </Button>
                <Button className="grey -lg gtm-cta" onClick={toggleModal} atag>
                  Book a Sales Call
                </Button>
              </FlexButtons>
            </Box>
            <Box size={55} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="MyHRPayHero_Hero.png"
                alt="MyHR & PayHero Leave Management"
                rounded
                addShadow
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoCheckmarkCircle />
                <br />
                Fast, accurate payroll
              </h3>
              <p>
                Spend less time getting your payroll right with powerful online
                timesheet tools, employee self-service, expenses, leave
                management and zero touch payday filing.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoPersonCircle />
                <br />
                Expert HR support
              </h3>
              <p>
                Keep on top of your people management with top notch HR
                software, backed by expert HR support. Easily manage hiring,
                onboarding, compliance, documentation, performance and change.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCloudDone />
                <br />
                Better together
              </h3>
              <p>
                Connecting the best <Link to="/payroll">payroll</Link> and HR
                apps with powerful two-way integration makes things easy for
                employees, reduces duplicate effort and errors, and saves your
                business money.
              </p>
            </Box>
          </Row>
          <CustomerQuoteLarge
            quote="Having our payroll & HR system integrated allows our staff to manage their leave and other data from a single source."
            name="Brett Pearson"
            company="Eat My Lunch"
            hero="EMLStoryHero.jpg"
            pic="Brett_Circle.png"
            logo="EML_Logo.png"
            videoLink="https://player.vimeo.com/video/726581500"
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper className="-textCenter">
          <h2>How does the MyHR integration work?</h2>
          <Row stackGap={40}>
            <Box size={33} stackGap={15}>
              <NumberBadge>1</NumberBadge>
              <div>
                <h3>Employee sync</h3>
                <p>
                  Once you've connected your PayHero account with MyHR, employee
                  data will be synced between both systems. When a new person
                  signs their employment agreement, their employee record is
                  created and available inside MyHR and PayHero.
                </p>
                <p></p>
              </div>
            </Box>
            <Box size={33} stackGap={15}>
              <NumberBadge>2</NumberBadge>
              <div>
                <h3>Leave requests</h3>
                <p>
                  MyHR pulls live data from PayHero so managers and employees
                  are always seeing accurate, up-to-date leave balances and
                  calendars. Employees can use their self-service MyHR portal to
                  view their current, correct leave balances, apply for leave
                  and have it approved.
                </p>
              </div>
            </Box>
            <Box size={33} stackGap={15}>
              <NumberBadge>3</NumberBadge>
              <div>
                <h3>Easy payroll compliance</h3>
                <p>
                  Approved leave requests automatically sync with PayHero and
                  the employee's leave balances and wage payments are calculated
                  when you process the pay. Leave balances and calculations are
                  driven from the rules engine in PayHero, which makes payroll
                  compliance as easy as possible - even for variable hour staff.
                </p>
              </div>
            </Box>
          </Row>
          <Image
            alt="MyHR + PayHero Integration"
            filename="MyHRPayHeroGraphic.png"
            addShadow
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50}>
              <img
                className="-center"
                src={PayHeroLogo}
                alt="PayHero | Online Payroll Made Super Easy"
                height="80"
              />
            </Box>
            <Box size={50} stackGap={30}>
              <div>
                <h2>About PayHero</h2>
                <h4>
                  PayHero is perfect for Kiwi companies that want stress-free
                  and compliant <Link to="/payroll">online payroll</Link>.
                  Especially those with a part time or flexible workforce, who
                  face unique challenges tracking employee time accurately and
                  calculating the right holiday entitlements for their staff.
                </h4>
              </div>
              <ul>
                <li>
                  Accurately capture employee hours with online timesheets,
                  employee mobile app or photo clock in and out.
                </li>
                <li>
                  Automatically calculate pay and holiday entitlements from
                  online timesheets and work patterns.
                </li>
                <li>
                  Connect to payday filing and we’ll send your PAYE information
                  to IRD.
                </li>
                <li>
                  Automatically send payroll transactions to Xero and track wage
                  costs with account codes or tracking categories.
                </li>
                <li>Expert phone and email support from our NZ-based team.</li>
              </ul>
              <Link className="link-arrow" to="/signup">
                Get Started for Free <IoIosArrowForward />
              </Link>
            </Box>
          </Row>
          <hr />
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50} stackGap={30}>
              <div>
                <h2>About MyHR</h2>
                <h4>
                  MyHR is top notch{" "}
                  <a href="https://www.myhr.works/nz/" target="_blank">
                    HR software
                  </a>
                  , backed by expert HR support. It’s like having a virtual HR
                  department with all your people systems and documentation
                  covered, and on-call help when you need it.
                </h4>
              </div>
              <ul>
                <li>
                  On-call Team - Expert HR advice from qualified HR consultants
                  who’ll support you and manage any employee issue.
                </li>
                <li>
                  Performance Reviews - Customizable performance review
                  software. Add KPI’s, highlight competencies for improvement,
                  and design personal development plans.
                </li>
                <li>
                  Data Security - All your employee data stored securely in the
                  AWS cloud with access provided safely to managers and key
                  business stakeholders.
                </li>
                <li>
                  Hiring & Onboarding - E-sign an employment agreement quickly
                  to secure the best candidate and get an induction process
                  supported and prepared by our expert HR Consultants.
                </li>
                <li>
                  Issues & Change Management - Make changes to your business
                  with the support of an HR expert navigating your legal
                  obligations and requirements.
                </li>
              </ul>
              <p>
                Find out more or{" "}
                <a
                  href="https://www.myhr.works/nz/trial"
                  hrefLang="en"
                  media="all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  try MyHR for free
                </a>
                .
              </p>
            </Box>
            <Box size={50}>
              <img
                className="-center"
                src={MyHRLogo}
                alt="MyHR Integration | PayHero"
                height="120"
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction
        title="Connect PayHero & MyHR today"
        description="Employee onboarding, leave management & payroll compliance made super easy."
        showSalesButton
      />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default MyHR;
